import React, { useState, useEffect } from 'react';

import Layout from '../organisms/layout';
import Seo from '../organisms/seo';
import FasciaDiErrore from '../molecules/fascia-di-errore/fascia-di-errore';
import { graphql, useStaticQuery } from 'gatsby';

const NotFoundPage = () => {
  const { notFoundPageData } = useStaticQuery(graphql`
    {
      notFoundPageData: allLiferayModulePaginaErrore(
        filter: { liferayFields: { title: { eq: "Pagina - Errore - 404" } } }
      ) {
        nodes {
          liferayFields {
            langCode
          }
          button1Text {
            content {
              slug1 {
                value
              }
            }
            value
          }
          button2Text {
            content {
              slug2 {
                value
              }
            }
            value
          }
          errorCode {
            value
          }
          errorDescription {
            value
          }
          separatorText {
            value
          }
        }
      }
    }
  `);
  const [language, setLanguage] = useState(null);
  const [ready, setReady] = useState(false);

  const nodeLanguage =
    notFoundPageData.nodes.find((node) => {
      return node?.liferayFields?.langCode === language;
    }) || 'it-IT'; // fallback nel caso il redattore non pubblichi in inglese. In italiano invece ci sarà sempre

  const pageSettings = {
    pageType: 'business',
    showChangeLanguageButton: false,
    disableLinkLogo: false,
    linkLangMap: { '': '' },
    langCode: language || 'it-IT',
    hideSocialButton: false,
  }; //Fallback nel caso in cui la pagina non abbia i custom field salvati o sia una pagina statica.

  useEffect(() => {
    const lang = navigator.language;
    if (lang === 'it-IT' || lang === 'en-US') {
      setLanguage(lang);
    } else setLanguage('it-IT');
    setReady(true);
  }, []);

  return (
    <>
      {ready && (
        <Layout pageSettings={pageSettings}>
          <Seo
            title='404: Not found'
            robots='noindex, nofollow'
            link='/404.html'
          />
          <div className='container'>
            <FasciaDiErrore data={nodeLanguage} />
          </div>
        </Layout>
      )}
    </>
  );
};

export default NotFoundPage;
